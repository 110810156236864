import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams, link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../../firebase-config'; // Import Firebase config
import Nav from '../Nav';
import HomeBanner from '../HomeBanner';
import Footer from '../Footer';
import { BsDot } from 'react-icons/bs';
import '../page/Page.css';

function PresenterDetails() {
  const { id } = useParams();
  const [presenter, setPresenter] = useState(null);

  // Fetch a specific presenter by ID
  useEffect(() => {
    const fetchPresenter = async () => {
      try {
        const docRef = doc(db, 'presenters', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setPresenter({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.log('No such presenter!');
        }
      } catch (error) {
        console.error('Error fetching presenter:', error);
      }
    };

    fetchPresenter();
  }, [id]);

  const imageStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    objectFit: 'cover',
  };

  const imageStylemobile = {
    width: '100%',
    height: '100px',
    borderRadius: '10px',
    objectFit: 'cover',
    marginBottom: '10px',
  };

  if (!presenter) return <p>Chargement...</p>;

  return (
    <div>
      <Nav />
      <HomeBanner />
      <div className='presenterPage-container'>
        <div className='detailsHeader'>
          <div className='dtailsBanner'>
            <img src={presenter.photo} alt={presenter.name} style={imageStyle} />
          </div>
          <p className='banner-content author'>
            {presenter.name} 
          </p>
          <p className='banner-content'>{presenter.biography}</p>
          <Link to={-1} style={{ textDecoration: 'none' }}>
            <button className="backbtn">
              <span style={{ width: '50px', height: '50px' }}>
                <img src="/go_back_icon.png" alt="" style={imageStyle} />
              </span>
              <span>Retour</span>
              <span style={{ width: '50px', height: '50px' }}>
                <img src="/go_back_icon.png" alt="" style={imageStyle} />
              </span>
            </button>
          </Link>
        </div>
      </div>


      <div className='page-container-mobile'>
        <div className='detailsHeader'>
          <div className='dtailsBanner'>
            <div className="imageHolder"></div>
            <img src={presenter.photo} alt={presenter.name} style={imageStyle} />
          </div>
          <p className='banner-content author'>
            {presenter.name} 
          </p>
          <p className='banner-content'>{presenter.biography}</p>
          <Link to={-1} style={{ textDecoration: 'none' }}>
            <button className="backbtn">
              <span style={{ width: '50px', height: '50px' }}>
                <img src="/go_back_icon.png" alt="" style={imageStylemobile} />
              </span>
              <span>Retour</span>
              <span style={{ width: '50px', height: '50px' }}>
                <img src="/go_back_icon.png" alt="" style={imageStylemobile} />
              </span>
            </button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PresenterDetails;
